.onlineOrderFont{
  font-size: 14px !important;
}

.onlineOrderTitleFont{
  font-size: 14px !important;
}

.opacityText{
  opacity: 0.8;
}

.flex-section {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
}

.flex-col-scroll {
  flex-grow: 1;
  overflow: auto;
  min-height: 100%;
}

.orderDetailsSmall{
  font-size: 12px !important;
  font-weight: 600;
}

.attributes{
  background-color: #D0EFFF;
  margin: 3px 3px 0 3px !important;
  padding: 3px 10px 3px 10px !important;
  border-radius: 4px;
  font-size: 12px;
  color: #000;
  font-weight: 600;
  text-transform: capitalize;
}

.ordersGrid::-webkit-scrollbar {
  width: 10px;
  display: none
}

.ordersGrid::-webkit-scrollbar-track {
  background: #a4d6ed;
}

.ordersGrid::-webkit-scrollbar-thumb {
  background: #1F6386;
  border-radius: 5px;
}

.ordersGrid::-webkit-scrollbar-thumb:hover {
  background: #1F6386;
}