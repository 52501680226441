// @import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Text:ital,wght@0,300..700;1,300..700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
// $primary-font: 'Inter', sans-serif;
$primary-font: 'Ubuntu', sans-serif;
$secondary-font: 'Open Sans', sans-serif;

$primary: #003049;
$green: #36CD1D;
$orange: #FB9000;
$properties: #D0EFFF;

$white: #fff;
$black: #000;

$sub-text: #6a6a6a;

// body {
//   font-family: $primary-font !important;
//   // font-weight: 400;
//   font-style: normal !important;
// }

.secondary-font {
  font-family: $primary-font !important;
}

.img-fluid{
    max-width: 100%;
    height: auto;
}

.error-message{
    margin: 0;
    padding: 0;
    position: relative;
    top: -5px;
    color: red;
    font-size: 11px;
}

.open{
    background-color: #000 !important;
    display: none;
}

.active{
    background-color: #061711 !important;
}

.d-flex{
    display: flex !important;
}

.fw-light{
    font-weight: 400 !important;
}

.cursor-p{
    cursor: pointer;
}

.paymentButton {
  background-color: #003049;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 67%;
  height: 43px;
  align-items: center;
  margin: 15px 0px 0 82px;
  padding: 18px;
  border-radius: 30px;
  color: #fff;

  &.disabled {
    background-color: #CCCCCC;
    color: #000000;
    pointer-events: none;
  }
}

.clickedChip {
  background-color: #D0EFFF !important;
  color: #000 !important;
  font-weight: 500 !important;
}

.unclickedChip {
  background-color: #fff !important;
  color: #000 !important;
}

.clickedCategoryChip {
  background-color: #003049 !important;
  color: #fff !important;
  font-weight: 500 !important;
}

.unclickedCategoryChip {
  background-color: #fff !important;
  color: #000;
}

.shadowed-box {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
}

.selected-product{
  border: 3px solid #0074B0;
}

// Custom styles for the Switch component

.customSwitchRoot {
  width: 42px;
  height: 26px;
  padding: 0;

  .customSwitchSwitchBase {
    padding: 1px;

    &.Mui-checked {
      transform: translateX(16px);
      color: #fff;

      & + .MuiSwitch-track {
        opacity: 1;
        background-color: #4caf50;
      }
    }

    &.Mui-focusVisible .MuiSwitch-thumb {
      color: #4caf50;
      border: 6px solid #fff;
    }
  }

  .customSwitchThumb {
    width: 24px;
    height: 24px;
  }

  .customSwitchTrack {
    border-radius: 13px;
    opacity: 1;
    background-color: #bebebe;
  }
}

.errorText{
  margin-top: 3px !important;
  font-size: 12px !important;
}

.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked, .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
    color: #003049 !important;
}

:root {
  --toastify-color-success: #215577 !important;
  --toastify-color-error: #d23232 !important;
}