/* Calendar.css */
.calendar-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.calendar {
  padding: 20px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.days-of-week {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 27px;
  text-align: center;
  font-size: 15px;
  margin-bottom: 15px;
}

.calendar-days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
//   gap: 15px;
}

.calendar-day {
  text-align: center;
  padding: 10px;
  border: 1px solid #ccc;
  padding: 10px 35px 10px 35px;
}

.holiday{
    background-color: #FFAEAE;
}

.empty-day {
  visibility: hidden;
}

.dayNumber{
    font-size: 15px !important;
    position: relative;
    right: 18px;
}

.counter{
    font-size: 12px !important;
    background-color: #34D719;
    border-radius: 50px;
    position: relative;
    top: 2px;
    left: 28px;
}