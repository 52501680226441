.primary-button-container {
  background-color: $primary;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  color: white;
  font-weight: 500;
  padding: 15px 0 15px 0;
  height: 100% !important;
  width: 100% !important;

  .title{
    font-size: 13px;
  }

  &:hover {
    background-color: #000;
  }

  &.disabled {
    // background-color: $primary;
    background-color: gray !important;
    cursor: not-allowed;
  }

  &.spinner {
    display: flex;
    align-items: center;
  }
}

.circle {
  width: 15px;
  height: 15px;
  position: relative;
  margin-right: 10px;
  border: 3px solid #ccc;
  border-top: 3px solid #fff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (min-width: 992px) {
  .primary-button-container {
    padding: 10px;
    
    .title{
      font-size: 14px;
    }
  }
}

@media screen and (min-width: 1200px) {
  .primary-button-container {
    padding: 7px;
    
    .title{
      font-size: 15px;
    }
  }
}

@media screen and (min-width: 1400px) {
  .primary-button-container {
    padding: 18px;
    
    .title{
      font-size: 17px;
    }
  }
}