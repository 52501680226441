.sideBar{
    .sideBar-btnContainer{
        font-size: 12px;
        margin: 0px;
    }
    .sideBar-button{
        border-radius: 4px;
        margin: 10px 42px 10px 42px;
        align-self: center;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        align-content: center;
    }
    .sideBar-btnText{
        text-align: center;
        margin-top: 7px;
    }

    @media screen and ( min-width: 768px ) {
        .sideBar-button{
            border-radius: 4px;
            margin: 10px 32px 10px 32px;
            align-self: center;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            align-content: center;
        }
        .sideBar-btnText{
            text-align: center;
            margin-top: 7px;
        }   
    }
}