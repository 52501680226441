@import './global.scss';

// Components
@import './components/Navbar.scss';
@import './components/Sidebar.scss';
@import './components/PrimaryButton.scss';
@import './components/ShiftCalendar.scss';
@import './components/RiderModal.scss';

// Screens
@import './screens/AuthScreens.scss';
@import './screens/OnlineOrders.scss';

.full-height{
  height: 100vh !important;
}

.container-fluid {
  margin: 2rem auto;
  text-align: center;
}