.loginLogo {
  position: fixed;
  background-color: #0C0F14;
  text-align: center;
  padding-top: 15px;
  height: 90px;

  .head {
    font-size: 19px;
  }

  .para{
    font-size: 14px;
  }

  // @media screen and (min-width: 992px) {
  //   padding-top: 8px;
  //   .head {
  //     font-size: 21px;
  //   }
  //   .para{
  //       font-size: 18px;
  //   }
  // }

  // @media screen and (min-width: 1200px) {
  //   padding-top: 0px;
  //   .head {
  //     font-size: 35px;
  //   }
  //   .para{
  //       font-size: 21px;
  //   }
  // }

  // @media screen and (min-width: 1400px) {
  //   .head {
  //     font-size: 40px;
  //   }
  //   .para{
  //       margin-top: -6px;
  //       font-size: 24px;
  //   }
  // }
}