.riderModal{
    background-color: #ECECEC;
    scrollbar-width: thin;
    .title{
        font-size: 18px;
        color: #1f1f1f;
        padding-bottom: 7px;
        font-weight: 500;
    }  
    .riderBox{
        background-color: #fff !important;
        cursor: pointer;
        display: flex;
        padding: 12px 25px;
        border-radius: 11px;
    }
    .disabled{
        background-color: #c6c6c6 !important;
    }
    .profileImage{
        border-radius: 50%;
        height: 80px;
        width: 80px;
        object-fit: cover;
        margin-right: 20px;
    }
    .selected{
        background-color: #D0EFFF !important;
        border: 2px solid #003049;
    }
    .riderName{
        font-size: 16px;
        padding-bottom: 5px;
    }
    .riderDetails{
        font-size: 14px;
    }
}

.riderSelectButton{
    background-color: #003049;
    width: 90%;
    border-radius: 900px;
    color: #fff;
    padding: 12px 0;
    font-weight: 500px;
    margin: 0 20px 10px 20px;
}

.riderSelectButton:hover{
    background-color: #1f1f1f;
}